<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-5 mb-2">
          <div style="padding:177.78% 0 0 0;position:relative;">
            <iframe src="https://player.vimeo.com/video/1002674631?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    style="position:absolute;top:0;left:0;width:100%;height:100%;" title="TAK! Udało się!"
                    autoplay="1"></iframe>
          </div>
        </div>
        <div class="col-md-7">
          <b-card>
            <b-card-text>
              <h4>⚠️OSTRZEŻENIE⚠️</h4>
              <p>Tylko minuty dzielą Cię od tego, by ChemMaster zmienił Twoje życie. READY? Już za chwilę…</p>
              <ul>
                <li>nauczysz się chemii od zera (tak, tak, od samych podstaw)…</li>
                <li>…i bez nudy! Bo kolejne lekcje wciągają jak Netflix ❤️</li>
                <li>uzupełnisz braki i usystematyzujesz wiedzę,</li>
                <li>dzięki super mnemotechnikom, quizom i wyzwaniom nawet najtrudniejsze tematy same wejdą Ci w głowę (i w niej zostaną!),</li>
                <li>będziesz mieć pewność przerobienia 100% tematów, które obowiązują na maturze #gotowinawszystko,</li>
                <li>…i naprawdę polubisz chemię. Tak serio 😉</li>
              </ul>
              <p>
                Masz pytania? Wiesz, gdzie mnie znaleźć.<br /><br />
                Przytulaki,<br />
                Sonia
              </p>
            </b-card-text>

            <b-link :to="{name:'my-courses'}" class="btn btn-success my-2">
              Hop do Twoich kursów
            </b-link>
          </b-card>
          <b-card>
            <b-card-text>
              <b-container class="mb-3">
                <b-row>
                  <b-col>
                    <h4>Zamówienie</h4>
                    <p><strong>Data zamówienia:</strong> {{ orderCreatedAt }}</p>
                    <p><strong>Kwota zamówienia: </strong> {{ order.total }} zł</p>
                  </b-col>
                  <b-col>
                    <h4>Płatność</h4>
                    <p><strong>Status:</strong> {{ order.status }}</p>
                    <p><strong>Kwota zamówienia brutto: </strong> {{ order.total }} zł</p>
                  </b-col>
                </b-row>
              </b-container>
              <b-table
                  responsive="sm"
                  :items="order.items"
                  :fields="fields"
              />
            </b-card-text>
          </b-card>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {
  BCard, BCardText, BContainer, BRow, BCol, BTable, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BCard,
    BCardText,
    BContainer,
    BRow,
    BCol,
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Nazwa',
        },
        {
          key: 'quantity',
          label: 'Ilość',
        },
        {
          key: 'total',
          label: 'Cena',
          formatter: value => `${value} zł`,
        },
      ],
      orderId: '',
      order: {},
    }
  },
  computed: {
    orderCreatedAt() {
      if (typeof this.order.createdAt !== 'undefined') return new Date(this.order.createdAt).toISOString().substr(0, 10)

      return ''
    },
  },
  created() {
    this.fetchOrder(this.$route.params.orderId)
  },
  beforeRouteLeave(to, from, next) {
    localStorage.setItem('executed', 'false')
    next()
  },
  methods: {
    fetchOrder(orderId) {
      this.$http.get(`${this.$store.state.apiDomain}/api/my/order/${orderId}`).then(response => {
        this.order = response.data.order

        window.dataLayer = window.dataLayer || []
        const items = this.order.items.map(product => (
            {
              item_name: product.name,
              item_id: product.id,
              price: product.price,
              item_category: product.category,
              quantity: product.quantity,
            }));

        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            currency: this.order.currency,
            value: this.order.total,
            tax: this.order.vat,
            shipping: 0.00,
            transaction_id: this.order.id,
            coupon: this.order.coupons,
            items: items,
          },
        })
      })
    },
  },
}
</script>

<style>

</style>
